import React, { useEffect, useState } from "react";
import { alpha, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typed from "react-typed";
import Lottie from "react-lottie";
import "./Hero.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require("../../../Assets/globe-animation.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 981px)").matches
  );
  const { t } = useTranslation("global");

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    const mediaQueryList = window.matchMedia("(max-width: 981px)");

    // Initial check
    setMatches(mediaQueryList.matches);

    // Add listener
    mediaQueryList.addEventListener("change", handler);

    // Clean up the listener on component unmount
    return () => {
      mediaQueryList.removeEventListener("change", handler);
    };
  }, []);

  const handleNavigate = (pathName) => {
    navigate(pathName);
  };

  return (
    <Grid container spacing={matches ? 0 : 5} className="heroContainer">
      <Grid item container xs={12} md={6} alignItems={"center"}>
        <Box data-aos={isMd ? "fade-right" : "fade-up"}>
          <Box marginBottom={2} className="animatedWordsContainer">
            <Typography
              variant="h4"
              color="text.primary"
              sx={{ fontWeight: 700 }}
              className="titleDescription"
            >
              LifeTech IT Solutions <br />
              {t("hero.heroTitle")}{" "}
              <Typography
                color={"primary"}
                component={"span"}
                variant={"inherit"}
                sx={{
                  background: `linear-gradient(180deg, transparent 82%, ${alpha(
                    "#027d7c",
                    0.3
                  )} 0%)`,
                }}
              >
                <Typed
                  strings={[
                    `${t("hero.firstAnimatedWord")}`,
                    `${t("hero.secondAnimatedWord")}`,
                    `${t("hero.thirdAnimatedWord")}`,
                    `${t("hero.fourthAnimatedWord")}`,
                    'Transformation'
                  ]}
                  typeSpeed={110}
                  loop={true}
                  className="animatedWords"
                />
              </Typography>
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography variant="h6" component="p" color="text.secondary">
              {t("hero.firstDescription")} <br />
              {t("hero.secondDescription")}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "stretched", sm: "flex-start" }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth={isMd ? false : true}
              onClick={() => handleNavigate("/contact")}
            >
              {t("hero.buttonText")}
            </Button>
          </Box>
        </Box>
      </Grid>
      <div className="globeContainer">
        <Lottie
          options={defaultOptions}
          isClickToPauseDisabled={true}
          style={{
            cursor: "auto",
            width: matches ? "415px" : "500px",
            marginTop: 20,
          }}
        />
      </div>
    </Grid>
  );
};

export default Hero;
