/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import teamPic from "../../../Assets/about.svg";
import Fade from "react-reveal/Fade";
import NavBar from "../../Shared/Navbar/Navbar";
import Footer from "../Footer/Footer";
import ScrollTop from "../../Shared/ScrollTop/ScrollTop";
import "./About.css";
import InovationImg from "../../../Assets/inovation.svg";
import QualityImg from "../../../Assets/quality.svg";
import ClientImg from "../../../Assets/client.svg";
import CollaboarationImg from "../../../Assets/collaboration.svg";
import IntergrityImg from "../../../Assets/intergrity.svg";
import AgilityImg from "../../../Assets/agile.svg";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation("global");
  useEffect(() => {
    const scrollTop = () => {
      window["scrollTo"]({ top: 0, behavior: "smooth" });
    };
    scrollTop();
  }, []);

  const coreValues = [
    {
      id: 1,
      name: t("about.clientFocusTitle"),
      img: ClientImg,
      description: t("about.clientFocusDesc"),
    },
    {
      id: 2,
      name: t("about.innovationTitle"),
      img: InovationImg,
      description: t("about.innovationDesc"),
    },
    {
      id: 3,
      name: t("about.collaborationTitle"),
      img: CollaboarationImg,
      description: t("about.collaborationDesc"),
    },
    {
      id: 4,
      name: t("about.qualityTitle"),
      img: QualityImg,
      description: t("about.qualityDesc"),
    },
    {
      id: 5,
      name: t("about.integrityTitle"),
      img: IntergrityImg,
      description: t("about.integrityDesc"),
    },
    {
      id: 6,
      name: t("about.agilityTitle"),
      img: AgilityImg,
      description: t("about.agilityDesc"),
    },
  ];

  return (
    <div>
      <NavBar />
      <section className="about overflow-hidden py-5">
        <div className="row w-100">
          <div className="row col-md-11 mx-auto">
            <div className="col-md-6 img">
              <>
                <img src={`${teamPic}`} alt="" className="img-fluid" />
              </>
            </div>
            <div className="col-md-6 ps-2">
              <>
                <p className="aboutUsText">{t("about.aboutUs")}</p>
                <h1 className="headerTitle">
                  {t("about.titleFirstPart")}
                  <span className="headerHighlight">
                    {" "}
                    {t("about.titleSecondPart")}
                  </span>
                  , {t("about.titleThirdPart")}
                </h1>
                <p className="headerContent">
                 {t('about.description')}
                </p>
              </>
            </div>

            <div className="coreValuesContainer">
              <h1 className="coreValuesTitle">{t('about.coreValuesTitle')}</h1>
              <p className="coreValuesDescription">
                {t('about.coreValuesDescription')}
              </p>
            </div>
            <div className="coreValues">
              {coreValues.map((item, index) => (
                <div key={index} className="coreItem">
                  <img src={item.img} className="coreItemImage" />
                  <h3 className="coreName">{item.name}</h3>
                  <p className="coreDescription">{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <ScrollTop />
      <Footer />
    </div>
  );
};

export default About;
