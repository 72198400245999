import React from "react";
import NavBar from "../../Shared/Navbar/Navbar";
import Footer from "../Footer/Footer";
import "./PrivacyPolicy.css";
import { useTranslation } from "react-i18next";

function PrivacyPolicy() {
  const { t } = useTranslation("global");

  return (
    <div>
      <NavBar />
      <div className="privacyPolicyContainer">
        <div className="privacyPolicyContent">
          <h3 className="privacyPolicySubTitle">{t("privacyPolicy.title")}</h3>
          <p className="privacyPolicyDesc">{t("privacyPolicy.welcomeText")}</p>
          <p className="privacyPolicyDesc">{t("privacyPolicy.intro")}</p>
          <p className="privacyPolicyDesc">
            {t("privacyPolicy.informationCollection")}
          </p>
          <p className="privacyPolicyDesc">
            {t("privacyPolicy.personallyInformation")}
          </p>
          <p className="privacyPolicyDesc">{t("privacyPolicy.name")}</p>
          <p className="privacyPolicyDesc">{t("privacyPolicy.email")}</p>
          <p className="privacyPolicyDesc">{t("privacyPolicy.phoneNumber")}</p>

          <p className="privacyPolicyDesc">
            {t("privacyPolicy.contactFormDescription")}
          </p>

          <h3 className="privacyPolicySubTitle">
            {t("privacyPolicy.changesToThisPolicy")}
          </h3>
          <p className="privacyPolicyDesc">
            {t("privacyPolicy.changesToThisPolicyDesc")}
          </p>

          <p className="privacyPolicyDesc">
            {t("privacyPolicy.rightsToChange")}
          </p>

          <h3 className="privacyPolicySubTitle">
            {t("privacyPolicy.contactUs")}
          </h3>
          <p className="privacyPolicyDesc">
            {t("privacyPolicy.contactUsDesc")}
          </p>

          <p className="privacyPolicyDesc">{t("privacyPolicy.thankYouText")}</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
