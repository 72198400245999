/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import NavBar from "../../Shared/Navbar/Navbar";
import Footer from "../Footer/Footer";
import ScrollTop from "../../Shared/ScrollTop/ScrollTop";
import { useLocation } from "react-router-dom";
import "./ServiceDetails.css";
import CheckIcon from "../../../Assets/check.svg";
import Technologies from "./Technologies/Technologies";
import Service from "./Service";
import WebDevelopmentImg from "../../../Assets/web-development.svg";
import MobileApp from "../../../Assets/mobile-app.svg";
import DesignImg from "../../../Assets/design.svg";
import ECommerceImg from "../../../Assets/e-commerce.svg";
import CloudServices from "../../../Assets/cloud-services.svg";
import ApiIntegration from "../../../Assets/api-integration.svg";
import TechnicalSupport from "../../../Assets/technical-support.svg";
import ReactLogo from "../../../Assets/logos/react-logo.svg";
import NextJsLogo from "../../../Assets/logos/next-js.svg";
import NodeJsLogo from "../../../Assets/logos/javascript.png";
import WordpressLogo from "../../../Assets/logos/wordpress.png";
import IOSLogo from "../../../Assets/logos/ios.png";
import AndroidLogo from "../../../Assets/logos/android.png";
import IllustratorLogo from "../../../Assets/logos/illustrator.png";
import AdobeXdLogo from "../../../Assets/logos/adobexd.png";
import PSLogo from "../../../Assets/logos/ps.png";
import FigmaLogo from "../../../Assets/logos/figma.png";
import MagentoLogo from "../../../Assets/logos/magento.png";
import ShopifyLogo from "../../../Assets/logos/shopify.png";
import WooLogo from "../../../Assets/logos/woo.png";
import BigCommerce from "../../../Assets/logos/bigCommerce.png";
import NestJsLogo from "../../../Assets/logos/nestJs.png";
import DockerLogo from "../../../Assets/logos/docker.png";
import AwsLogo from "../../../Assets/logos/aws.jpeg";
import PostgresLogo from "../../../Assets/logos/postgres.png";
import MongoDbLogo from "../../../Assets/logos/mongo.png";
import MySqlLogo from "../../../Assets/logos/mysql.png";
import GraphQlLogo from "../../../Assets/logos/graphql.png";
import { useTranslation } from "react-i18next";
import DataScience from "../../../Assets/data_science_1.webp";
import DataAnalysis from "../../../Assets/data-analysis.svg";
import ConsultingLogo from "../../../Assets/consulting.svg";
import CustomWebApplication from "../../../Assets/custom-web-application-2.jpg";
import PortraitWebDev from "../../../Assets/portrait-web-dev.jpg";
import WebDevCode from "../../../Assets/web-dev-code.jpg";
import MobileDevelopment from "../../../Assets/mobile-development.jpg";
import MobileDevelopmentPortrait from "../../../Assets/mobile-development-portrait.jpg";
import MobileDevelopment2 from "../../../Assets/mobile-dev-2.jpg";
import ApiDevelopment1 from "../../../Assets/api-development-1.jpg";
import ApiDevelopment2 from "../../../Assets/api-development-2.jpg";
import ApiDevelopmentPortrait from "../../../Assets/api-development-portrait.jpg";
import EcommercePortrait from "../../../Assets/e-commerce-portrait.jpg";
import Ecommerce1 from "../../../Assets/e-commerce-1.jpg";
import Ecommerce2 from "../../../Assets/e-commerce-2.jpg";
import UiUx1 from "../../../Assets/ui-ux-1.jpg";
import UiUx2 from "../../../Assets/ui-ux-2.jpeg";
import UiUxPortrait from "../../../Assets/ui-ux-portrait.jpg";
import Cloud1 from "../../../Assets/cloud-1.jpeg";
import Cloud2 from "../../../Assets/cloud-2.jpeg";
import CloudPortrait from "../../../Assets/cloud-portrait.jpeg";
import DataScience1 from "../../../Assets/data-science-1.jpg";
import DataSciencePortrait from "../../../Assets/portrait-data-science-2.jpg";
import DataScience2 from "../../../Assets/data-science-2.jpg";
import MaintenancePortrait from "../../../Assets/maintenance-portrait.jpg";
import Maintenance1 from "../../../Assets/maintenance-1.jpg";
import Maintenance2 from "../../../Assets/maintenance-2.jpg";
import Consulting1 from "../../../Assets/consulting-1.jpg";
import ConsultingPortrait from "../../../Assets/ConsultingPortrait.jpg";
import Consulting2 from "../../../Assets/consulting-2.jpg";
import DesignersIcon from "../../../Assets/designers.svg";
import DatabaseAdministrators from "../../../Assets/database-administrators.svg";
import DatabaseEngineer from "../../../Assets/data-engineer.svg";
import Designers2Icon from "../../../Assets/designers2.svg";
import SoftwareEngineers from "../../../Assets/software-engineers.svg";
import SystemEngineers from "../../../Assets/system-engineers.svg";
import PowerBiLogo from "../../../Assets/power-bi.png";
import SqlLogo from "../../../Assets/sql-logo.png";
import PythonImg from "../../../Assets/python.png";
import AzureLogo from "../../../Assets/logos/azure.png";
import GoogleCloud from "../../../Assets/logos/googleCloud.jpeg";
import RLanguageLogo from "../../../Assets/r-language.png";
import SnowFlakeLogo from "../../../Assets/snow-flake.png";
import DataIkuLogo from "../../../Assets/dataiku.png";

const ServiceDetails = () => {
  const location = useLocation();
  const [currentService, setCurrentService] = useState(null);
  const [selectedPage, setSelectedPage] = useState(1);
  const ITEMS_PER_PAGE = 3;
  const { t } = useTranslation("global");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const services = [
    {
      id: 1,
      name: t("services.service1Title"),
      description: t("services.service1Description"),
      additionalDescription: t("services.service1AdditionalDesc"),
      shortDescription: t("services.service1ShortDesc"),
      img: WebDevelopmentImg,
      detailsImg: PortraitWebDev,
      detailsImg2: CustomWebApplication,
      detailsImg3: WebDevCode,
      steps: [
        {
          id: 1,
          name: t("services.service1Step1Name"),
          description: t("services.service1Step1Descriptipn"),
        },
        {
          id: 2,
          name: t("services.service1Step2Name"),
          description: t("services.service1Step2Descriptipn"),
        },
        {
          id: 3,
          name: t("services.service1Step3Name"),
          description: t("services.service1Step3Descriptipn"),
        },
        {
          id: 4,
          name: t("services.service1Step4Name"),
          description: t("services.service1Step4Descriptipn"),
        },
        {
          id: 5,
          name: t("services.service1Step5Name"),
          description: t("services.service1Step5Descriptipn"),
        },
        {
          id: 6,
          name: t("services.service1Step6Name"),
          description: t("services.service1Step6Descriptipn"),
        },
        {
          id: 7,
          name: t("services.service1Step7Name"),
          description: t("services.service1Step7Descriptipn"),
        },
      ],
      technologies: [
        {
          id: 1,
          name: "React Js",
          img: ReactLogo,
          type: "Frontend",
        },
        {
          id: 2,
          name: "Next Js",
          img: NextJsLogo,
          type: "Frontend",
        },
        {
          id: 3,
          name: "Node Js",
          img: NodeJsLogo,
          type: "Backend",
        },
        {
          id: 4,
          name: "Wordpress",
          img: WordpressLogo,
          type: "Frontend",
        },
        { id: 5, name: "GraphQL", img: GraphQlLogo, type: "Frontend" },
      ],
      type: true,
    },
    {
      id: 2,
      name: t("services.service2Title"),
      description: t("services.service2Description"),
      img: MobileApp,
      additionalDescription: t("services.service2AdditionalDesc"),
      shortDescription: t("services.service2ShortDesc"),
      detailsImg: MobileDevelopmentPortrait,
      detailsImg2: MobileDevelopment,
      detailsImg3: MobileDevelopment2,
      steps: [
        {
          id: 1,
          name: t("services.service2Step1Name"),
          description: t("services.service2Step1Description"),
        },
        {
          id: 2,
          name: t("services.service2Step2Name"),
          description: t("services.service2Step2Description"),
        },
        {
          id: 3,
          name: t("services.service2Step3Name"),
          description: t("services.service2Step3Description"),
        },
        {
          id: 4,
          name: t("services.service2Step4Name"),
          description: t("services.service2Step4Description"),
        },
        {
          id: 5,
          name: t("services.service2Step5Name"),
          description: t("services.service2Step5Description"),
        },
        {
          id: 6,
          name: t("services.service2Step6Name"),
          description: t("services.service2Step6Description"),
        },
        {
          id: 7,
          name: t("services.service2Step7Name"),
          description: t("services.service2Step7Description"),
        },
        {
          id: 8,
          name: t("services.service2step8Name"),
          description: t("services.service2Step8Description"),
        },
      ],
      technologies: [
        {
          id: 1,
          name: "React Native",
          img: ReactLogo,
          type: "Frontend",
        },
        {
          id: 2,
          name: "iOS",
          img: IOSLogo,
          type: "Frontend",
        },
        {
          id: 3,
          name: "Android",
          img: AndroidLogo,
          type: "Frontend",
        },
        {
          id: 4,
          name: "Node",
          img: NodeJsLogo,
          type: "Backend",
        },
      ],
      type: true,
    },
    {
      id: 3,
      name: t("services.service6Title"),
      description: t("services.service6Description"),
      additionalDescription: t("services.service6AdditionalDesc"),
      shortDescription: t("services.service6ShortDesc"),
      img: ApiIntegration,
      detailsImg: ApiDevelopmentPortrait,
      detailsImg2: ApiDevelopment1,
      detailsImg3: ApiDevelopment2,
      steps: [
        {
          id: 1,
          name: t("services.service6Step1Name"),
          description: t("services.service6Step1Description"),
        },
        {
          id: 2,
          name: t("services.service6Step2Name"),
          description: t("services.service6Step2Description"),
        },
        {
          id: 3,
          name: t("services.service6Step3Name"),
          description: t("services.service6Step3Description"),
        },
        {
          id: 4,
          name: t("services.service6Step4Name"),
          description: t("services.service6Step4Description"),
        },
        {
          id: 5,
          name: t("services.service6Step5Name"),
          description: t("services.service6Step5Description"),
        },
        {
          id: 6,
          name: t("services.service6Step6Name"),
          description: t("services.service6Step6Description"),
        },
        {
          id: 7,
          name: t("services.service6Step7Name"),
          description: t("services.service6Step7Description"),
        },
        {
          id: 8,
          name: t("services.service6Step8Name"),
          description: t("services.service6Step8Description"),
        },
        {
          id: 9,
          name: t("services.service6Step9Name"),
          description: t("services.service6Step9Description"),
        },
      ],
      technologies: [
        {
          id: 1,
          name: "Node",
          img: NodeJsLogo,
          type: "Backend",
        },
        {
          id: 2,
          name: "Nest",
          img: NestJsLogo,
          type: "Backend",
        },
        {
          id: 3,
          name: "Docker",
          img: DockerLogo,
          type: "Backend",
        },
        {
          id: 4,
          name: "AWS",
          img: AwsLogo,
          type: "Backend",
        },
        {
          id: 5,
          name: "Postgres",
          img: PostgresLogo,
          type: "Backend",
        },
        { id: 6, name: "Mongo DB", img: MongoDbLogo, type: "Backend" },
        { id: 7, name: "My SQL", img: MySqlLogo, type: "Backend" },
      ],
      type: true,
    },
    {
      id: 4,
      name: t("services.service4Title"),
      description: t("services.service4Description"),
      img: ECommerceImg,
      additionalDescription: t("services.service4AdditionalDesc"),
      shortDescription: t("services.service4ShortDesc"),
      detailsImg: EcommercePortrait,
      detailsImg2: Ecommerce1,
      detailsImg3: Ecommerce2,
      steps: [
        {
          id: 1,
          name: t("services.service4Step1Name"),
          description: t("services.service4Step1Description"),
        },
        {
          id: 2,
          name: t("services.service4Step2Name"),
          description: t("services.service4Step2Description"),
        },
        {
          id: 3,
          name: t("services.service4Step3Name"),
          description: t("services.service4Step3Description"),
        },
        {
          id: 4,
          name: t("services.service4Step4Name"),
          description: t("services.service4Step4Description"),
        },
        {
          id: 5,
          name: t("services.service4Step5Name"),
          description: t("services.service4Step5Description"),
        },
        {
          id: 6,
          name: t("services.service4Step6Name"),
          description: t("services.service4Step6Description"),
        },
        {
          id: 7,
          name: t("services.service4Step7Name"),
          description: t("services.service4Step7Description"),
        },
        {
          id: 8,
          name: t("services.service4Step8Name"),
          description: t("services.service4Step8Description"),
        },
        {
          id: 9,
          name: t("services.service4Step9Name"),
          description: t("services.service4Step9Description"),
        },
      ],
      technologies: [
        {
          id: 1,
          name: "Shopify",
          img: ShopifyLogo,
          type: "Frontend",
        },
        {
          id: 2,
          name: "Magento",
          img: MagentoLogo,
          type: "Frontend",
        },
        {
          id: 3,
          name: "WooCommerce",
          img: WooLogo,
          type: "Frontend",
        },
        {
          id: 4,
          name: "BigCommerce",
          img: BigCommerce,
          type: "Frontend",
        },
      ],
      type: true,
    },
    {
      id: 5,
      name: t("services.service3Title"),
      description: t("services.service3Description"),
      additionalDescription: t("services.service3AdditionalDesc"),
      img: DesignImg,
      shortDescription: t("services.service3ShortDesc"),
      detailsImg: UiUxPortrait,
      detailsImg2: UiUx1,
      detailsImg3: UiUx2,
      steps: [
        {
          id: 1,
          name: t("services.service3Step1Name"),
          description: t("services.service3Step1Description"),
        },
        {
          id: 2,
          name: t("services.service3Step2Name"),
          description: t("services.service3Step2Description"),
        },
        {
          id: 3,
          name: t("services.service3Step3Name"),
          description: t("services.service3Step3Description"),
        },
        {
          id: 4,
          name: t("services.service3Step4Name"),
          description: t("services.service3Step4Description"),
        },
        {
          id: 5,
          name: t("services.service3Step5Name"),
          description: t("services.service3Step5Description"),
        },
        {
          id: 6,
          name: t("services.service3Step6Name"),
          description: t("services.service3Step6Description"),
        },
        {
          id: 7,
          name: t("services.service3Step7Name"),
          description: t("services.service3Step7Description"),
        },
        {
          id: 8,
          name: t("services.service3Step8Name"),
          description: t("services.service3Step8Description"),
        },
      ],
      technologies: [
        {
          id: 1,
          name: "Illustrator",
          img: IllustratorLogo,
          type: "Frontend",
        },
        {
          id: 2,
          name: "Adobe XD",
          img: AdobeXdLogo,
          type: "Frontend",
        },
        {
          id: 3,
          name: "Photoshop",
          img: PSLogo,
          type: "Frontend",
        },
        {
          id: 4,
          name: "Figma",
          img: FigmaLogo,
          type: "Frontend",
        },
      ],
      type: true,
    },
    {
      id: 6,
      name: t("services.service5Title"),
      description: t("services.service5Description"),
      img: CloudServices,
      additionalDescription: t("services.service5AdditionalDesc"),
      shortDescription: ``,
      detailsImg: CloudPortrait,
      detailsImg2: Cloud1,
      detailsImg3: Cloud2,
      steps: [
        {
          id: 1,
          name: t("services.service5Step1Name"),
          description: t("services.service5Step1Description"),
        },
        {
          id: 2,
          name: t("services.service5Step2Name"),
          description: t("services.service5Step2Description"),
        },
        {
          id: 3,
          name: t("services.service5Step3Name"),
          description: t("services.service5Step3Description"),
        },
        {
          id: 4,
          name: t("services.service5Step4Name"),
          description: t("services.service5Step4Description"),
        },
        {
          id: 5,
          name: t("services.service5Step5Name"),
          description: t("services.service5Step5Description"),
        },
        {
          id: 6,
          name: t("services.service5Step6Name"),
          description: t("services.service5Step6Description"),
        },
        {
          id: 7,
          name: t("services.service5Step7Name"),
          description: t("services.service5Step7Description"),
        },
      ],
      technologies: [
        {
          id: 1,
          name: "AWS",
          img: AwsLogo,
          type: "Frontend",
        },
        {
          id: 2,
          name: "Azure",
          img: AzureLogo,
          type: "Frontend",
        },
        {
          id: 3,
          name: "Google Cloud",
          img: GoogleCloud,
          type: "Frontend",
        },
      ],
      type: true,
    },
    {
      id: 7,
      name: t("services.service8Title"),
      description: t("services.service8Description"),
      additionalDescription: t("services.service8AdditionalDesc"),
      shortDescription: ``,
      extraDescription1: t("services.extraDescription1"),
      extraDescription2: t("services.extraDescription2"),
      extraImg: DataScience,
      img: DataAnalysis,
      detailsImg: DataSciencePortrait,
      detailsImg2: DataScience1,
      detailsImg3: DataScience2,
      steps: [
        {
          id: 1,
          name: t("services.service8Step1Name"),
          description: t("services.service8Step1Description"),
        },
        {
          id: 2,
          name: t("services.service8Step2Name"),
          description: t("services.service8Step2Description"),
        },
        {
          id: 3,
          name: t("services.service8Step3Name"),
          description: t("services.service8Step3Description"),
        },
        {
          id: 4,
          name: t("services.service8Step4Name"),
          description: t("services.service8Step4Description"),
        },
        {
          id: 5,
          name: t("services.service8Step5Name"),
          description: t("services.service8Step5Description"),
        },
        {
          id: 6,
          name: t("services.service8Step6Name"),
          description: t("services.service8Step6Description"),
        },
        {
          id: 7,
          name: t("services.service8Step7Name"),
          description: t("services.service8Step7Description"),
        },
        {
          id: 8,
          name: t("services.service8Step8Name"),
          description: t("services.service8Step8Description"),
        },
      ],
      technologies: [
        {
          id: 1,
          name: "Microsoft Power BI",
          img: PowerBiLogo,
          type: "Frontend",
        },
        {
          id: 2,
          name: "SQL",
          img: SqlLogo,
          type: "Frontend",
        },
        {
          id: 3,
          name: "Python",
          img: PythonImg,
          type: "Frontend",
        },
        {
          id: 4,
          name: "R language",
          img: RLanguageLogo,
          type: "Frontend",
        },
        {
          id: 5,
          name: "Snowflake",
          img: SnowFlakeLogo,
          type: "Frontend",
        },
        {
          id: 6,
          name: "Dataiku",
          img: DataIkuLogo,
          type: "Frontend",
        },
      ],
      type: false,
    },
    {
      id: 8,
      name: t("services.service9Title"),
      description: t("services.service9Description"),
      additionalDescription: t("services.service9AdditionalDesc"),
      shortDescription: ``,
      img: ConsultingLogo,
      detailsImg: ConsultingPortrait,
      detailsImg2: Consulting1,
      detailsImg3: Consulting2,
      steps: [
        {
          id: 1,
          name: t("services.service9Step1Name"),
          description: t("services.service9Step1Description"),
        },
        {
          id: 2,
          name: t("services.service9Step2Name"),
          description: t("services.service9Step2Description"),
        },
        {
          id: 3,
          name: t("services.service9Step3Name"),
          description: t("services.service9Step3Description"),
        },
        {
          id: 4,
          name: t("services.service9Step4Name"),
          description: t("services.service9Step4Description"),
        },
        {
          id: 5,
          name: t("services.service9Step5Name"),
          description: t("services.service9Step5Description"),
        },
        {
          id: 6,
          name: t("services.service9Step6Name"),
          description: t("services.service9Step6Description"),
        },
        {
          id: 7,
          name: t("services.service9Step7Name"),
          description: t("services.service9Step7Description"),
        },
        {
          id: 8,
          name: t("services.service9Step8Name"),
          description: t("services.service9Step8Description"),
        },
      ],
      technologies: [],
      type: false,
    },
    {
      id: 9,
      name: t("services.service7Title"),
      description: t("services.service7Description"),
      additionalDescription: t("services.service7AdditionalDesc"),
      shortDescription: ``,
      img: TechnicalSupport,
      detailsImg: MaintenancePortrait,
      detailsImg2: Maintenance1,
      detailsImg3: Maintenance2,
      steps: [
        {
          id: 1,
          name: t("services.service7Step1Name"),
          description: t("services.service7Step1Description"),
        },
        {
          id: 2,
          name: t("services.service7Step2Name"),
          description: t("services.service7Step2Description"),
        },
        {
          id: 3,
          name: t("services.service7Step3Name"),
          description: t("services.service7Step3Description"),
        },
        {
          id: 4,
          name: t("services.service7Step4Name"),
          description: t("services.service7Step4Description"),
        },
        {
          id: 5,
          name: t("services.service7Step5Name"),
          description: t("services.service7Step5Description"),
        },
        {
          id: 6,
          name: t("services.service7Step6Name"),
          description: t("services.service7Step6Description"),
        },
        {
          id: 7,
          name: t("services.service7Step7Name"),
          description: t("services.service7Step7Description"),
        },
        {
          id: 8,
          name: t("services.service7Step8Name"),
          description: t("services.service7Step8Description"),
        },
      ],
      technologies: [],
      type: true,
    },
  ];

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname) {
      const serviceId = pathname.charAt(pathname.length - 1);
      const service = services.filter((item) => item.id === +serviceId);
      setCurrentService(...service);
    }
  }, [location.pathname, services]);

  if (!currentService) {
    return (
      <div>
        <NavBar />
        <p>Service not found</p>
        <Footer />
      </div>
    );
  }

  const startIndex = (selectedPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentItems = services.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber) => {
    setSelectedPage(pageNumber);
  };

  const numberOfPages = [1, 2, 3];

  const team = [
    {
      name: t("services.developers"),
      icon: DesignersIcon,
      technologies: [
        "Javascript",
        "Python",
        "Java",
        "C#",
        t("teamOfExperts.andMore"),
      ],
    },
    {
      name: t("services.designer"),
      icon: Designers2Icon,
      technologies: [
        "Zeplin",
        "Figma",
        "Adobe Photoshop",
        "Sketch",
        t("teamOfExperts.andMore"),
      ],
    },
    {
      name: t("services.softwareEngineers"),
      icon: SoftwareEngineers,
      technologies: [
        "Node Js",
        "React Js",
        "React Native",
        "Next Js",
        t("teamOfExperts.andMore"),
      ],
    },
    {
      name: t("services.systemEngineers"),
      icon: SystemEngineers,
      technologies: [
        "Java",
        "Linux / Windows",
        "AWS",
        "Oracle",
        t("teamOfExperts.andMore"),
      ],
    },
    {
      name: t("services.databaseAdministrators"),
      icon: DatabaseAdministrators,
      technologies: [
        "Oracle",
        "MySQL",
        "Google Cloud SQL",
        "AWS Database",
        t("teamOfExperts.andMore"),
      ],
    },
    {
      name: t("services.dataEngineer"),
      icon: DatabaseEngineer,
      technologies: [
        "SQL",
        "Python",
        "R Language",
        "Snowflake",
        t("teamOfExperts.andMore"),
      ],
    },
  ];

  return (
    <div>
      <NavBar />
      <section className="serviceDetailsContainer">
        {currentService.name !== t("services.service9Title") ? (
          <div className="serviceContent">
            <div className="serviceHeaderContainer">
              <div className="serviceHeaderItem1">
                <h2 className="serviceTitle">{currentService.name}</h2>
                <p className="serviceDescription">
                  {currentService.description}
                  <br />
                  {currentService.additionalDescription}
                </p>
              </div>
              <div className="serviceHeaderItem2">
                <img src={currentService.detailsImg} className="serviceImage" />
                <div className="collageColumns">
                  <img
                    src={currentService.detailsImg2}
                    className="collageImage2"
                  />
                  <img
                    src={currentService.detailsImg3}
                    className="collageImage2"
                  />
                </div>
              </div>
            </div>
            <div
              className={
                currentService.type
                  ? "additionalInfosContainer"
                  : "extraAdditionalInfosContainer"
              }
            >
              {currentService.type ||
              currentService.name === t("services.service9Title") ? (
                <h3 className="serviceTitleWhy">
                  {t("services.whyChooseOur")}{" "}
                  {currentService.name === t("services.service9Title")
                    ? t("services.service9ExtraName")
                    : currentService.name}
                  ?
                </h3>
              ) : null}
              <div style={{ marginTop: currentService.type ? 0 : 0 }}>
                <div className="stepsContainer">
                  {currentService.type ? (
                    <div className="steps">
                      {currentService.steps.map((item, index) => (
                        <div className="stepItem">
                          <img src={CheckIcon} className="checkIcon" />
                          <div style={{ marginTop: 10 }}>
                            <h5>{item.name}</h5>
                            <p className="stepDescription">
                              {item.description}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="extraDescriptionContainer">
                      <p
                        className="extraDescription"
                        style={{ whiteSpace: "pre-line" }}
                      >
                        {currentService.extraDescription1}
                      </p>
                      <br />
                      <img src={currentService.extraImg} className="extraImg" />
                      <br />
                      <p className="extraDescription">
                        {currentService.extraDescription2}
                      </p>
                      {currentService.technologies.length > 0 ? (
                        <Technologies
                          technologies={currentService.technologies}
                        />
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
              {currentService.type &&
              currentService.type &&
              currentService.technologies.length > 0 ? (
                <Technologies technologies={currentService.technologies} />
              ) : null}
            </div>
            <h3 className="otherServices">{t("services.otherServices")}</h3>
            <div className="row mt-3 container mx-auto justify-content-center">
              {currentItems?.map((service, index) => (
                <Service service={service} key={index} />
              ))}
            </div>
            <div className="paginationContainer">
              {numberOfPages.map((item, index) => (
                <h3
                  key={index}
                  onClick={() => handlePageChange(item)}
                  className={`${
                    selectedPage === item ? "activePage" : "defaultPage"
                  }`}
                >
                  {item}
                </h3>
              ))}
            </div>
          </div>
        ) : (
          <div className="serviceContent">
            <div className="serviceHeaderContainer">
              <div className="serviceHeaderItem1">
                <h2 className="serviceTitle">{currentService.name}</h2>
                <p className="serviceDescription">
                  {currentService.description}
                </p>
              </div>
              <div className="serviceHeaderItem2">
                <img src={currentService.detailsImg} className="serviceImage" />
                <div className="collageColumns">
                  <img
                    src={currentService.detailsImg2}
                    className="collageImage2"
                  />
                  <img
                    src={currentService.detailsImg3}
                    className="collageImage2"
                  />
                </div>
              </div>
            </div>
            <div className="content">
              <h4 className="ourTeamTitle">{t("services.ourTeamOfExperts")}</h4>
              <div className="teamSection">
                {team.map((item) => (
                  <div className="teamItem">
                    <img src={item.icon} className="teamIcon" />
                    <h5 className="teamPositionName">{item.name}</h5>
                    <div className="teamTechnologies">
                      {item.technologies.map((item) => (
                        <ul>
                          <li>{item}</li>
                        </ul>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <h3 className="ourTeamTitle">
              {t("services.whyChooseOurExperts")}
            </h3>
            <div className="lastSection">
              <h5>{t("services.diverseSkills")}</h5>
              <p>{t("services.diverseSkillsDesc")}</p>
              <h5>{t("services.colloborativeApproach")}</h5>
              <p>{t("services.colloborativeApproachDesc")}</p>
              <h5>{t("services.provenTrackRecord")}</h5>
              <p>{t("services.provenTrackRecordDesc")}</p>
              <h5>{t("services.customSolutions")}</h5>
              <p>{t("services.customSolutionsDesc")}</p>
            </div>

            <h3 className="otherServices">{t("services.otherServices")}</h3>
            <div className="row mt-3 container mx-auto justify-content-center">
              {currentItems?.map((service, index) => (
                <Service service={service} key={index} />
              ))}
            </div>
            <div className="paginationContainer">
              {numberOfPages.map((item, index) => (
                <h3
                  key={index}
                  onClick={() => handlePageChange(item)}
                  className={`${
                    selectedPage === item ? "activePage" : "defaultPage"
                  }`}
                >
                  {item}
                </h3>
              ))}
            </div>
          </div>
        )}
      </section>
      <ScrollTop />
      <Footer />
    </div>
  );
};

export default ServiceDetails;
