import React from "react";
import Service from "./Service";
import Spinner from "../../Shared/Spinner/Spinner";
import WebDevelopmentImg from "../../../Assets/web-development.svg";
import MobileApp from "../../../Assets/mobile-app.svg";
import DesignImg from "../../../Assets/design.svg";
import ECommerceImg from "../../../Assets/e-commerce.svg";
import CloudServices from "../../../Assets/cloud-services.svg";
import ApiIntegration from "../../../Assets/api-integration.svg";
import TechnicalSupport from "../../../Assets/technical-support.svg";
import WebDevelopmentDetailsImg from "../../../Assets/test.jpeg";
import MobileDevelopmentImg from "../../../Assets/mobileDevelopment.jpeg";
import UiUxDesignImg from "../../../Assets/uiUx.jpg";
import EcommerceImg from "../../../Assets/eCommerce.png";
import CloudServicesImg from "../../../Assets/cloud.jpeg";
import ApiIntegrationImg from "../../../Assets/apiIntegration.png";
import TechSupportImg from "../../../Assets/tech-support.png";
import ReactLogo from "../../../Assets/logos/react-logo.svg";
import NextJsLogo from "../../../Assets/logos/next-js.svg";
import NodeJsLogo from "../../../Assets/logos/javascript.png";
import WordpressLogo from "../../../Assets/logos/wordpress.png";
import IOSLogo from "../../../Assets/logos/ios.png";
import AndroidLogo from "../../../Assets/logos/android.png";
import IllustratorLogo from "../../../Assets/logos/illustrator.png";
import AdobeXdLogo from "../../../Assets/logos/adobexd.png";
import PSLogo from "../../../Assets/logos/ps.png";
import FigmaLogo from "../../../Assets/logos/figma.png";
import MagentoLogo from "../../../Assets/logos/magento.png";
import ShopifyLogo from "../../../Assets/logos/shopify.png";
import WooLogo from "../../../Assets/logos/woo.png";
import BigCommerce from "../../../Assets/logos/bigCommerce.png";
import NestJsLogo from "../../../Assets/logos/nestJs.png";
import DockerLogo from "../../../Assets/logos/docker.png";
import AwsLogo from "../../../Assets/logos/aws.jpeg";
import PostgresLogo from "../../../Assets/logos/postgres.png";
import MongoDbLogo from "../../../Assets/logos/mongo.png";
import MySqlLogo from "../../../Assets/logos/mysql.png";
import GraphQlLogo from "../../../Assets/logos/graphql.png";
import AzureLogo from "../../../Assets/logos/azure.png";
import GoogleCloud from "../../../Assets/logos/googleCloud.jpeg";
import { useTranslation } from "react-i18next";
import DataScience from '../../../Assets/data-science.svg';
import DataAnalysis from '../../../Assets/data-analysis.svg';
import DataEngineeringLogo from '../../../Assets/data-engineering.jpeg';
import ConsultingLogo from '../../../Assets/consulting.svg';
import Consulting from '../../../Assets/consulting.jpeg';

const Services = () => {
  const { t } = useTranslation("global");

  const services = [
    {
      id: 1,
      name: t("services.service1Title"),
      description: t("services.service1Description"),
      additionalDescription: t("services.service1AdditionalDesc"),
      shortDescription: t("services.service1ShortDesc"),
      img: WebDevelopmentImg,
      detailsImg: WebDevelopmentDetailsImg,
      steps: [
        {
          id: 1,
          name: t("services.service1Step1Name"),
          description: t("services.service1Step1Descriptipn"),
        },
        {
          id: 2,
          name: t("services.service1Step2Name"),
          description: t("services.service1Step2Descriptipn"),
        },
        {
          id: 3,
          name: t("services.service1Step3Name"),
          description: t("services.service1Step3Descriptipn"),
        },
        {
          id: 4,
          name: t("services.service1Step4Name"),
          description: t("services.service1Step4Descriptipn"),
        },
        {
          id: 5,
          name: t("services.service1Step5Name"),
          description: t("services.service1Step5Descriptipn"),
        },
        {
          id: 6,
          name: t("services.service1Step6Name"),
          description: t("services.service1Step6Descriptipn"),
        },
        {
          id: 7,
          name: t("services.service1Step7Name"),
          description: t("services.service1Step7Descriptipn"),
        },
      ],
      technologies: [
        {
          id: 1,
          name: "React Js",
          img: ReactLogo,
          type: "Frontend",
        },
        {
          id: 2,
          name: "Next Js",
          img: NextJsLogo,
          type: "Frontend",
        },
        {
          id: 3,
          name: "Node Js",
          img: NodeJsLogo,
          type: "Backend",
        },
        {
          id: 4,
          name: "Wordpress",
          img: WordpressLogo,
          type: "Frontend",
        },
        { id: 5, name: "GraphQL", img: GraphQlLogo, type: "Frontend" },
      ],
      type: true
    },
    {
      id: 2,
      name: t("services.service2Title"),
      description: t("services.service2Description"),
      img: MobileApp,
      additionalDescription: t("services.service2AdditionalDesc"),
      shortDescription: t("services.service2ShortDesc"),
      detailsImg: MobileDevelopmentImg,
      steps: [
        {
          id: 1,
          name: t("services.service2Step1Name"),
          description: t("services.service2Step1Description"),
        },
        {
          id: 2,
          name: t("services.service2Step2Name"),
          description: t("services.service2Step2Description"),
        },
        {
          id: 3,
          name: t("services.service2Step3Name"),
          description: t("services.service2Step3Description"),
        },
        {
          id: 4,
          name: t("services.service2Step4Name"),
          description: t("services.service2Step4Description"),
        },
        {
          id: 5,
          name: t("services.service2Step5Name"),
          description: t("services.service2Step5Description"),
        },
        {
          id: 6,
          name: t("services.service2Step6Name"),
          description: t("services.service2Step6Description"),
        },
        {
          id: 7,
          name: t("services.service2Step7Name"),
          description: t("services.service2Step7Description"),
        },
        {
          id: 8,
          name: t("services.service2step8Name"),
          description: t("services.service2Step8Description"),
        },
      ],
      technologies: [
        {
          id: 1,
          name: "React Native",
          img: ReactLogo,
          type: "Frontend",
        },
        {
          id: 2,
          name: "iOS",
          img: IOSLogo,
          type: "Frontend",
        },
        {
          id: 3,
          name: "Android",
          img: AndroidLogo,
          type: "Frontend",
        },
        {
          id: 4,
          name: "Node",
          img: NodeJsLogo,
          type: "Backend",
        },
      ],
      type: true
    },
    {
      id: 3,
      name: t("services.service6Title"),
      description: t("services.service6Description"),
      additionalDescription: t("services.service6AdditionalDesc"),
      shortDescription: t("services.service6ShortDesc"),
      img: ApiIntegration,
      detailsImg: ApiIntegrationImg,
      steps: [
        {
          id: 1,
          name: t("services.service6Step1Name"),
          description: t("services.service6Step1Description"),
        },
        {
          id: 2,
          name: t("services.service6Step2Name"),
          description: t("services.service6Step2Description"),
        },
        {
          id: 3,
          name: t("services.service6Step3Name"),
          description: t("services.service6Step3Description"),
        },
        {
          id: 4,
          name: t("services.service6Step4Name"),
          description: t("services.service6Step4Description"),
        },
        {
          id: 5,
          name: t("services.service6Step5Name"),
          description: t("services.service6Step5Description"),
        },
        {
          id: 6,
          name: t("services.service6Step6Name"),
          description: t("services.service6Step6Description"),
        },
        {
          id: 7,
          name: t("services.service6Step7Name"),
          description: t("services.service6Step7Description"),
        },
        {
          id: 8,
          name: t("services.service6Step8Name"),
          description: t("services.service6Step8Description"),
        },
        {
          id: 9,
          name: t("services.service6Step9Name"),
          description: t("services.service6Step9Description"),
        },
      ],
      technologies: [
        {
          id: 1,
          name: "Node",
          img: NodeJsLogo,
          type: "Backend",
        },
        {
          id: 2,
          name: "Nest",
          img: NestJsLogo,
          type: "Backend",
        },
        {
          id: 3,
          name: "Docker",
          img: DockerLogo,
          type: "Backend",
        },
        {
          id: 4,
          name: "AWS",
          img: AwsLogo,
          type: "Backend",
        },
        {
          id: 5,
          name: "Postgres",
          img: PostgresLogo,
          type: "Backend",
        },
        { id: 6, name: "Mongo DB", img: MongoDbLogo, type: "Backend" },
        { id: 7, name: "My SQL", img: MySqlLogo, type: "Backend" },
      ],
      type: true
    },
    {
      id: 4,
      name: t("services.service4Title"),
      description: t("services.service4Description"),
      img: ECommerceImg,
      additionalDescription: t("services.service4AdditionalDesc"),
      shortDescription: t("services.service4ShortDesc"),
      detailsImg: EcommerceImg,
      steps: [
        {
          id: 1,
          name: t("services.service4Step1Name"),
          description: t("services.service4Step1Description"),
        },
        {
          id: 2,
          name: t("services.service4Step2Name"),
          description: t("services.service4Step2Description"),
        },
        {
          id: 3,
          name: t("services.service4Step3Name"),
          description: t("services.service4Step3Description"),
        },
        {
          id: 4,
          name: t("services.service4Step4Name"),
          description: t("services.service4Step4Description"),
        },
        {
          id: 5,
          name: t("services.service4Step5Name"),
          description: t("services.service4Step5Description"),
        },
        {
          id: 6,
          name: t("services.service4Step6Name"),
          description: t("services.service4Step6Description"),
        },
        {
          id: 7,
          name: t("services.service4Step7Name"),
          description: t("services.service4Step7Description"),
        },
        {
          id: 8,
          name: t("services.service4Step8Name"),
          description: t("services.service4Step8Description"),
        },
        {
          id: 9,
          name: t("services.service4Step9Name"),
          description: t("services.service4Step9Description"),
        },
      ],
      technologies: [
        {
          id: 1,
          name: "Shopify",
          img: ShopifyLogo,
          type: "Frontend",
        },
        {
          id: 2,
          name: "Magento",
          img: MagentoLogo,
          type: "Frontend",
        },
        {
          id: 3,
          name: "WooCommerce",
          img: WooLogo,
          type: "Frontend",
        },
        {
          id: 4,
          name: "BigCommerce",
          img: BigCommerce,
          type: "Frontend",
        },
      ],
      type: true
    },
    {
      id: 5,
      name: t("services.service3Title"),
      description: t("services.service3Description"),
      additionalDescription: t("services.service3AdditionalDesc"),
      img: DesignImg,
      shortDescription: t("services.service3ShortDesc"),
      detailsImg: UiUxDesignImg,
      steps: [
        {
          id: 1,
          name: t("services.service3Step1Name"),
          description: t("services.service3Step1Description"),
        },
        {
          id: 2,
          name: t("services.service3Step2Name"),
          description: t("services.service3Step2Description"),
        },
        {
          id: 3,
          name: t("services.service3Step3Name"),
          description: t("services.service3Step3Description"),
        },
        {
          id: 4,
          name: t("services.service3Step4Name"),
          description: t("services.service3Step4Description"),
        },
        {
          id: 5,
          name: t("services.service3Step5Name"),
          description: t("services.service3Step5Description"),
        },
        {
          id: 6,
          name: t("services.service3Step6Name"),
          description: t("services.service3Step6Description"),
        },
        {
          id: 7,
          name: t("services.service3Step7Name"),
          description: t("services.service3Step7Description"),
        },
        {
          id: 8,
          name: t("services.service3Step8Name"),
          description: t("services.service3Step8Description"),
        },
      ],
      technologies: [
        {
          id: 1,
          name: "Illustrator",
          img: IllustratorLogo,
          type: "Frontend",
        },
        {
          id: 2,
          name: "Adobe XD",
          img: AdobeXdLogo,
          type: "Frontend",
        },
        {
          id: 3,
          name: "Photoshop",
          img: PSLogo,
          type: "Frontend",
        },
        {
          id: 4,
          name: "Figma",
          img: FigmaLogo,
          type: "Frontend",
        },
      ],
      type: true
    },
    {
      id: 6,
      name: t("services.service5Title"),
      description: t("services.service5Description"),
      img: CloudServices,
      additionalDescription: t("services.service5AdditionalDesc"),
      shortDescription: ``,
      detailsImg: CloudServicesImg,
      steps: [
        {
          id: 1,
          name: t("services.service5Step1Name"),
          description: t("services.service5Step1Description"),
        },
        {
          id: 2,
          name: t("services.service5Step2Name"),
          description: t("services.service5Step2Description"),
        },
        {
          id: 3,
          name: t("services.service5Step3Name"),
          description: t("services.service5Step3Description"),
        },
        {
          id: 4,
          name: t("services.service5Step4Name"),
          description: t("services.service5Step4Description"),
        },
        {
          id: 5,
          name: t("services.service5Step5Name"),
          description: t("services.service5Step5Description"),
        },
        {
          id: 6,
          name: t("services.service5Step6Name"),
          description: t("services.service5Step6Description"),
        },
        {
          id: 7,
          name: t("services.service5Step7Name"),
          description: t("services.service5Step7Description"),
        },
      ],
      technologies: [
        {
          id: 1,
          name: "AWS",
          img: AwsLogo,
          type: "Frontend",
        },
        {
          id: 2,
          name: "Azure",
          img: AzureLogo,
          type: "Frontend",
        },
        {
          id: 3,
          name: "Google Cloud",
          img: GoogleCloud,
          type: "Frontend",
        },
      ],
      type: true
    },
    {
      id: 7,
      name: t("services.service8Title"),
      description: t("services.service8Description"),
      additionalDescription: t("services.service8AdditionalDesc"),
      shortDescription: ``,
      extraDescription1: t("services.extraDescription1"),
      extraDescription2: t("services.extraDescription2"),
      extraImg: DataScience,
      img: DataAnalysis,
      detailsImg: DataEngineeringLogo,
      steps: [
        {
          id: 1,
          name: t("services.service8Step1Name"),
          description: t("services.service8Step1Description"),
        },
        {
          id: 2,
          name: t("services.service8Step2Name"),
          description: t("services.service8Step2Description"),
        },
        {
          id: 3,
          name: t("services.service8Step3Name"),
          description: t("services.service8Step3Description"),
        },
        {
          id: 4,
          name: t("services.service8Step4Name"),
          description: t("services.service8Step4Description"),
        },
        {
          id: 5,
          name: t("services.service8Step5Name"),
          description: t("services.service8Step5Description"),
        },
        {
          id: 6,
          name: t("services.service8Step6Name"),
          description: t("services.service8Step6Description"),
        },
        {
          id: 7,
          name: t("services.service8Step7Name"),
          description: t("services.service8Step7Description"),
        },
        {
          id: 8,
          name: t("services.service8Step8Name"),
          description: t("services.service8Step8Description"),
        },
      ],
      technologies: [],
      type: false
    },
    {
      id: 8,
      name: t("services.service9Title"),
      description: t("services.service9Description"),
      additionalDescription: t("services.service9AdditionalDesc"),
      shortDescription: ``,
      img: ConsultingLogo,
      detailsImg: Consulting,
      steps: [
        {
          id: 1,
          name: t("services.service9Step1Name"),
          description: t("services.service9Step1Description"),
        },
        {
          id: 2,
          name: t("services.service9Step2Name"),
          description: t("services.service9Step2Description"),
        },
        {
          id: 3,
          name: t("services.service9Step3Name"),
          description: t("services.service9Step3Description"),
        },
        {
          id: 4,
          name: t("services.service9Step4Name"),
          description: t("services.service9Step4Description"),
        },
        {
          id: 5,
          name: t("services.service9Step5Name"),
          description: t("services.service9Step5Description"),
        },
        {
          id: 6,
          name: t("services.service9Step6Name"),
          description: t("services.service9Step6Description"),
        },
        {
          id: 7,
          name: t("services.service9Step7Name"),
          description: t("services.service9Step7Description"),
        },
        {
          id: 8,
          name: t("services.service9Step8Name"),
          description: t("services.service9Step8Description"),
        },
      ],
      technologies: [],
      type: false
    }, 
    {
      id: 9,
      name: t("services.service7Title"),
      description: t("services.service7Description"),
      additionalDescription: t("services.service7AdditionalDesc"),
      shortDescription: ``,
      img: TechnicalSupport,
      detailsImg: TechSupportImg,
      steps: [
        {
          id: 1,
          name: t("services.service7Step1Name"),
          description: t("services.service7Step1Description"),
        },
        {
          id: 2,
          name: t("services.service7Step2Name"),
          description: t("services.service7Step2Description"),
        },
        {
          id: 3,
          name: t("services.service7Step3Name"),
          description: t("services.service7Step3Description"),
        },
        {
          id: 4,
          name: t("services.service7Step4Name"),
          description: t("services.service7Step4Description"),
        },
        {
          id: 5,
          name: t("services.service7Step5Name"),
          description: t("services.service7Step5Description"),
        },
        {
          id: 6,
          name: t("services.service7Step6Name"),
          description: t("services.service7Step6Description"),
        },
        {
          id: 7,
          name: t("services.service7Step7Name"),
          description: t("services.service7Step7Description"),
        },
        {
          id: 8,
          name: t("services.service7Step8Name"),
          description: t("services.service7Step8Description"),
        },
      ],
      technologies: [],
      type: true
    },
  ];

  return (
    <section id="services" className="services">
      <h4 className="miniTitle text-center">{t("services.title")}</h4>
      {services.length === 0 && (
        <div className="spinner text-center">
          <Spinner />
        </div>
      )}
      <div className="row mt-4 container mx-auto justify-content-center">
        {services?.map((service, index) => (
          <Service service={service} key={index} />
        ))}
      </div>
    </section>
  );
};

export default Services;
