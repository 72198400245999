import React from "react";
import NotFoundImg from "../Assets/page-not-found.jpeg";
import './NotFound.css';

const NotFound = () => {
  return (
    <div className="notFoundContainer">
      <img
        src={`${NotFoundImg}`}
        alt=""
        className="notFoundImg"
      />
    </div>
  );
};

export default NotFound;
