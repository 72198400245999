import Dropdown from "react-bootstrap/Dropdown";
import { SET_USER_LANGUAGE, useAppContext } from "../../../context";

function LanguageDropDown({expanded, setExpanded}) {
  const {
    state: { language },
    dispatch,
  } = useAppContext();

  const handleChangeLanguage = (language) => {
    dispatch({ type: SET_USER_LANGUAGE, payload: language });
    setExpanded(!expanded)
  };

  return (
    <Dropdown style={{ backgroundColor: "#089292", borderRadius: 8}}>
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        style={{ backgroundColor: "#089292", borderColor: "#089292" }}
      >
        {language ? language.toUpperCase() : "EN"}
      </Dropdown.Toggle>
      <Dropdown.Menu
        style={{ width: 50, minWidth: "100%", borderColor: "#089292" }}
      >
        <Dropdown.Item onClick={() => handleChangeLanguage("en")}>
          EN
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleChangeLanguage("de")}>
          DE
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleChangeLanguage("fr")}>
          FR
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LanguageDropDown;
