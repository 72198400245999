import React from "react";
import "./HappyClient.css";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";

const HappyClient = () => {
  const { t } = useTranslation("global");

  const workDetails = [
    { title: t('stats.projects'), number: 2, id: 2 },
    { title:  t('stats.hoursOfSupport'), value: "7/7 - 365", id: 3 },
    { title: t('stats.teamMembers'), number: 5, id: 4 },
  ];

  return (
    <section className="ourValue">
      <div className="row container mx-auto happyClientContainer">
        {workDetails.map(({ title, number, id, value }) => {
          return (
            <div className="col-md-6 col-lg-3" key={id}>
              <div className="ourValueDetails">
                <span className={`valueIcon valueIcon${id}`}></span>
                <div>
                  <p className="ourValueTitle">{title}</p>
                  {number ? (
                    <h4 className="ourValueNumber">
                      <CountUp end={`${number}`} start={0} duration={5} />
                    </h4>
                  ) : (
                    <h4 className="ourValueNumber">{value}</h4>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default HappyClient;
