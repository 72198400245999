import Home from "../src/component/Home/Home/Home";
import { Routes, Route } from "react-router-dom";
import React, { createContext } from "react";
import About from "./component/Home/About/About";
import NotFound from "./component/NotFound";
import Contact from "./component/Home/Contact/Contact";
import ServiceDetails from "./component/Home/Services/ServiceDetails";
import PrivacyPolicy from "./component/Home/PrivacyPolicy/PrivacyPolicy";
export const UserContext = createContext();

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/service/details/:id" element={<ServiceDetails />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
