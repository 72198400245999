/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./Technologies.css";
import { useTranslation } from "react-i18next";

const Technologies = ({ technologies }) => {
  const { t } = useTranslation("global");
  return (
    <div className="technologiesContainer">
      <h1 className="technologiesTitle">{t('services.techStack')}</h1>
      <div className="technologies">
        {technologies.map((item, index) => (
          <div key={index} className="technologieCardItem">
            <img src={item.img} className="techImg" />
            <h4 className="technologieName">{item.name}</h4>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Technologies;
