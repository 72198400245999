/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import WhyChooseUsImg from "../../../Assets/why-choose-us.jpg";
import "./WhyChooseUs.css";
import { useTranslation } from "react-i18next";

function WhyChooseUs() {
  const { t } = useTranslation("global");

  return (
    <div className="whyChooseUsContainer">
      <div className="whyChooseUsDescriptionContainer">
        <h1 className="whyChooseUsTitle">{t('whyChooseUs.title')}</h1>
        <p className="whyChooseDesc">
          <span className="whyChooseSubDesc">
          {t('whyChooseUs.intro')}
          </span>
          <br />
          <br /> {t('whyChooseUs.description1')}
          <br />
          <br />
          {t('whyChooseUs.description2')}
        </p>
      </div>
      <div className="whyChooseUsImgContainer">
        <img src={WhyChooseUsImg} className="whyChooseUsImg" />
      </div>
    </div>
  );
}

export default WhyChooseUs;
