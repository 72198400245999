import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col } from "react-bootstrap";

const FooterInfo = ({
  data: { icon, info1, info2, info3, info4, info5, id, image, type },
}) => {
  return (
    <Col md={4}>
      <div
        className={
          !image
            ? `d-flex fContactInfo fContactInfo${id} align-items-start`
            : ""
        }
      >
        {image ? (
          <img
            src={image}
            className="smallLogo"
            onClick={() => {
              window["scrollTo"]({ top: 0, behavior: "smooth" });
            }}
          />
        ) : (
          <FontAwesomeIcon
            icon={icon}
            className={type === "contact" ? "fContactIcon" : "fLocationIcon"}
          />
        )}
        <div>
          <p className={`brnName${id}`}>{info1}</p>
          {info2 && <p>{info2}</p>}
          <div className="locationInfos">
            {info3 && <p>{info3}</p>}
            {info4 && <p>{info4}</p>}
            {info5 && <p>{info5}</p>}
          </div>
        </div>
      </div>
    </Col>
  );
};

export default FooterInfo;
