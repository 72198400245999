/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./Service.css";
import { useNavigate } from "react-router-dom";
import Arrow from "../../../Assets/arrow3.svg";

const Service = ({ service, key }) => {
  const { id, name, description, img } = service;
  const navigate = useNavigate();

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  const handleNavigate = () => {
    navigate(`/service/details/${id}`);
  };

  return (
    <div
      key={key}
      className="col-md-6 col-lg-4 service"
      onClick={() => handleNavigate()}
    >
      <div className="service-card">
        <div className="text-center">
          <img src={img} alt="" className="serviceImg" />
        </div>
        <h4 className="serviceName">{name}</h4>
        <p className="serviceDes">{truncateText(description, 80)}</p>
        <img src={Arrow} className="arrowIcon" />
      </div>
    </div>
  );
};

export default Service;
