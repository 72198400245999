import { useReducer } from "react";
import { useContext } from "react";
import { createContext } from "react";
import { getDecodedUser } from "../component/Login/LoginManager";
import { reducer } from "./reducer";
import en from "../../src/translations/en/global.json";
import de from "../../src/translations/de/global.json";
import fr from "../../src/translations/fr/global.json";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";

const AppContext = createContext();

const initialState = {
  user: getDecodedUser(),
  admin: false,
  selectedService: {},
  language: "",
};

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  i18next.init({
    interpolation: { escapeValue: false },
    lng: state.language ? state.language : "en",
    resources: {
      en: {
        global: en,
      },
      de: {
        global: de,
      },
      fr: {
        global: fr,
      },
    },
  });

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
