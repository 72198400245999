/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import "./Contact.css";
import swal from "sweetalert";
import NavBar from "../../Shared/Navbar/Navbar";
import Footer from "../Footer/Footer";
import ScrollTop from "../../Shared/ScrollTop/ScrollTop";
import PhoneIcon from "../../../Assets/mobile-phone.svg";
import WriteIcon from "../../../Assets/write.svg";
import FacebookIcon from "../../../Assets/facebook-icon.svg";
import InstagramIcon from "../../../Assets/instagram-icon.svg";
import LinkedInIcon from "../../../Assets/linkedin-icon.svg";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const { t } = useTranslation("global");
  const form = useRef();

  useEffect(() => {
    const scrollTop = () => {
      window["scrollTo"]({ top: 0, behavior: "smooth" });
    };
    scrollTop();
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        () => {
          e.preventDefault();
          e.target.reset();
          swal(
            t("contactPopUp.thankYou"),
            t("contactPopUp.description"),
            "success"
          );
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="contactContainer">
      <NavBar />
      <section id="contact">
        <Col md={11} className="mx-auto">
          <>
            <h1 className="contactTitle">{t("contact.title")}</h1>
            <p className="contactDescription">{t("contact.description")}</p>

            <div className="contactBoxes">
              <div className="boxItem">
                <img src={PhoneIcon} className="contactBoxImg" />
                <h4 className="boxItemTitle">{t("contact.callUs")}</h4>
                <p className="boxValue">0041799139215</p>
              </div>

              <div className="boxItem">
                <img src={WriteIcon} className="contactBoxImg" />
                <h4 className="boxItemTitle">{t("contact.writeUs")}</h4>
                <p className="boxValue">contact@lifetechs.ch</p>
              </div>
            </div>

            <div className="socialMediaContainer">
              <h1 className="socialMediaTitle">
                {t("contact.socialMediaTitle")}
              </h1>
              <p className="socialMediaDescription">
                {t("contact.socialMediaDesc")}
              </p>
              <div className="socialMediaIcons">
                <a href="https://www.linkedin.com/company/lifetech-it-solutions/">
                  <img src={LinkedInIcon} className="socialMediaIcon" />
                </a>
                <a href="https://www.instagram.com/lifetechitsolutions?igsh=anM0eXdmbzNiNTJv">
                  <img src={InstagramIcon} className="socialMediaIcon" />
                </a>
              </div>
            </div>
            <form
              onSubmit={sendEmail}
              className="contactForm"
              ref={form}
              name="contact"
              method="POST"
              data-netlify="true"
            >
              <h5 className="sectionTitle">{t("contact.sendUsAMessage")}</h5>
              <Row>
                <Col md={12} lg={6}>
                  <input
                    placeholder={t("contact.yourName")}
                    type="text"
                    required
                    className="inputText"
                    name="user_name"
                  />
                </Col>
                <Col md={12} lg={6}>
                  <input
                    placeholder={t("contact.phoneNumber")}
                    type="text"
                    required
                    className="inputText"
                    name="phone_number"
                  />
                </Col>
                <Col md={12}>
                  <input
                    placeholder={t("contact.yourEmail")}
                    type="email"
                    required
                    className="inputText"
                    name="user_email"
                  />
                </Col>
                <Col md={12}>
                  <textarea
                    placeholder={t("contact.yourMessage")}
                    required
                    className="inputText"
                    name="message"
                  ></textarea>
                </Col>
                <input type="hidden" name="form-name" value="name_of_my_form" />
              </Row>
              <button className="branBtn" type="submit">
                {t("contact.submitNow")}
              </button>
            </form>
          </>
        </Col>
      </section>
      <ScrollTop />
      <Footer />
    </div>
  );
};

export default Contact;
