/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { useState, useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import Logo from "../../../Assets/logo.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageDropDown from "../DropDown/DropDown";

const NavBar = () => {
  const [isSticky, setSticky] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation("global");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    });
  }, []);

  const navigate = useNavigate();

  const scrollTop = () => window["scrollTo"]({ top: 0, behavior: "smooth" });

  const handleNavigate = (routeName) => {
    setExpanded(true);
    return navigate(routeName);
  };

  return (
    <Navbar
      expanded={expanded}
      className={`navbar navbar-expand-lg navbar-light ${
        isSticky ? "navStyle" : "navDefault"
      }`}
      expand="lg"
    >
      <Container>
        <Navbar.Brand as={Link} to="/" onClick={scrollTop} className="navBrn">
          <img src={Logo} className="logoImg" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto mainNav" activeKey="/home">
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/"
                className="nav-link"
                onClick={() => {
                  window["scrollTo"]({ top: 0, behavior: "smooth" });
                  setExpanded(!expanded);
                }}
              >
                {t("navbar.homeLink")}
              </Nav.Link>
            </Nav.Item>
            {window.location.pathname !== "/contact" &&
            window.location.pathname !== "/about" &&
            !window.location.pathname.includes("/service/details/") ? (
              <Nav.Item>
                <Nav.Link
                  href="#services"
                  className="nav-link"
                  onClick={() => setExpanded(!expanded)}
                >
                  {t("navbar.servicesLink")}
                </Nav.Link>
              </Nav.Item>
            ) : (
              <Nav.Item>
                <Nav.Link
                  to="/"
                  className="nav-link"
                  onClick={() => {
                    handleNavigate("/");
                    window["scrollTo"]({ top: 0, behavior: "smooth" });
                    setExpanded(!expanded);
                  }}
                >
                  {t("navbar.servicesLink")}
                </Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item>
              <Nav.Link
                className="nav-link"
                onClick={() => {
                  handleNavigate("/about");
                  scrollTop();
                }}
              >
                {t("navbar.aboutLink")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className="nav-link"
                onClick={() => {
                  handleNavigate("/contact");
                  scrollTop();
                }}
              >
                {t("navbar.contactLink")}
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <div className="dropDownContainer">
          <LanguageDropDown expanded={expanded} setExpanded={setExpanded}/>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
