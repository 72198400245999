import React from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import FooterCol from "./FooterCol";
import "./Footer.css";
import FooterInfo from "./FooterInfo";
import { useTranslation } from "react-i18next";
import { faMobileAlt, faMapPin } from "@fortawesome/free-solid-svg-icons";
import SmallLogo from "../../../Assets/smallLogo.svg";

const Footer = () => {
  const { t } = useTranslation("global");
   const usefulLink = [
    { name: t('navbar.homeLink'), id: 1, link: "/" },
    { name: t('navbar.aboutLink'), id: 2, link: "/about" },
    { name: t('navbar.servicesLink'), id: 3, link: "/" },
    { name: t('navbar.contactLink'), id: 4, link: "/contact" },
  ];

  const footerInfo = [
    { icon: "", id: 1, image: SmallLogo },
    {
      icon: faMobileAlt,
      info1: "0041799139215",
      info2: "contact@lifetechs.ch",
      id: 2,
      type: 'contact'
    },
    {
      icon: faMapPin,
      info3: "Chemin des Cerisiers 26",
      info4: "1630 Bulle",
      info5: "Switzerland",
      id: 3,
      type: 'location'
    },
  ];

  const otherLinks = [{ name: t('navbar.privacyPolicyLink'), id: 13, link: '/privacy-policy' }];


  return (
    <section className="row footer">
      <Row
        className="col-md-11 mx-auto"
        style={{ maxWidth: "1320px", margin: "0 auto" }}
      >
        <Row className="align-items-center footerInfo">
          {footerInfo.map((data) => (
            <FooterInfo data={data} key={data.id} />
          ))}
        </Row>
        <Col md={6} lg={3} className="fAboutUs">
          <h5>{t("footer.followUs")}</h5>   
          <ul className="socialIcons">
            <li>
              <a href="https://www.instagram.com/lifetechitsolutions?igsh=anM0eXdmbzNiNTJv">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/lifetech-it-solutions/">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
            </li>
          </ul>
        </Col>
        <FooterCol key="2" menuItems={usefulLink} title={t('footer.usefullLink')} />
        <FooterCol key="4" menuItems={otherLinks} title={t('footer.otherLinks')} />
      </Row>
      <p className="copyRight">
        {t("footer.copyRight")} &copy; 2024
        <span className="fHighlight"> LifeTech IT Solutions</span>.{" "}
        {t("footer.allRightsReserved")}
      </p>
    </section>
  );
};

export default Footer;
