import React from "react";
import BuildTools from "../BuildTools/BuildTools";
import Footer from "../Footer/Footer";
import HappyClient from "../HappyClient/HappyClient";
import Header from "../Header/Header";
import Services from "../Services/Services";
import ScrollTop from "../../Shared/ScrollTop/ScrollTop";
import WhyChooseUs from "../WhyChooseUs/WhyChooseUs";

const Home = () => {
  return (
    <main>
      <Header />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <BuildTools />
      </div>
      <Services />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: '#F0F6FA'
        }}
      >
      <WhyChooseUs />
      </div>
      <HappyClient />
      <Footer />
      <ScrollTop />
    </main>
  );
};

export default Home;
