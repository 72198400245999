/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import PlanImg from "../../../Assets/plan.svg";
import DesignImg from "../../../Assets/design.svg";
import DevelopmentImg from "../../../Assets/development.svg";
import TestingImg from "../../../Assets/testing.svg";
import ProductionImg from "../../../Assets/production.svg";
import "./Processes.css";
import { useTranslation } from "react-i18next";

const Processes = () => {
  const { t } = useTranslation("global");

  const processes = [
    {
      id: 1,
      name: `${t("process.planningTitle")}`,
      description: `${t("process.planningDescription")}`,
      img: PlanImg,
    },
    {
      id: 2,
      name: `${t("process.designingTitle")}`,
      description: `${t("process.designingDescription")}`,
      img: DesignImg,
    },
    {
      id: 3,
      name: `${t("process.developmentTitle")}`,
      description: `${t("process.developmentDescription")}`,
      img: DevelopmentImg,
    },
    {
      id: 4,
      name: `${t("process.testingTitle")}`,
      description: `${t("process.testingDescription")}`,
      img: TestingImg,
    },
    {
      id: 5,
      name: `${t("process.productionTitle")}`,
      description: `${t("process.productionDescription")}`,
      img: ProductionImg,
    },
  ];

  return (
    <div className="processesContainer">
      {processes.map((item, index) => (
        <div key={index} className="processItem">
          <img src={item.img} className="processImg" />
          <h3 className="processTitle">{item.name}</h3>
            <p className="processDescription">{item.description}</p>
        </div>
      ))}
    
    </div>
  );
};

export default Processes;
