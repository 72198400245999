import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./BuildTools.css";
import Processes from "./Processes";
import { useTranslation } from "react-i18next";

const BuildTools = () => {
  const { t } = useTranslation("global");

  return (
    <Box className="ourProcessContainer">
      <Box marginBottom={4}>
        <Box marginBottom={2}>
          <Typography
            variant="h4"
            color="text.primary"
            align={"center"}
            gutterBottom
            sx={{
              fontWeight: 700,
              color: "#1876d2",
            }}
          >
            {t("process.title")}
          </Typography>
          <Box className="descriptionContainer">
            <Typography
              variant="h6"
              component="p"
              color="text.secondary"
              align={"center"}
              className="descriptionText"
            >
              {t("process.description")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Processes />
    </Box>
  );
};

export default BuildTools;
